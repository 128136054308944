body, h1, h2, h3, p, ul, li {
    margin: 0;
    padding: 0;
    text-align: center;
}

p {
    margin-bottom: 10px;
    font-size: 1vw;
}
  
a {
    text-decoration: none; 
    color: inherit;
}

.design-project {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: palevioletred;
    color: black;
    /* background-color: #FE569B; */
    padding: 70px;
}

.header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.header h1 {
    font-size: 1.5vw;    
    font-weight: 800;
    margin-bottom: 1vw;
    text-transform: uppercase;
    color: pink;
}

.header h2 {
    width: 25vw;
    font-size: 2vw;
    font-weight: 800;
    margin-bottom: 3vw;
    text-transform: uppercase;
    color: white;
}

.header p {
    width: 30vw;
    font-size: 1vw;
    margin-bottom: 3vw;
    color: white
}

.set {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    margin-left: 10vw;
    margin-right: 10vw;
}

.skill {
    border: 2px solid white;
    /* background-color: black; */
    background-color: white;
    padding: 10px 20px;
    /* background-color: rgba(255,255,255,0.3); */
    color: palevioletred;
    font-size: 0.8vw;
    font-weight: 900;
    margin: 5px;
}

.stack {
    border: 2px solid white;
    /* background-color: white; */
    padding: 10px 20px;
    color: white;
    font-size: 0.8vw;
    font-weight: 900;
    margin: 5px;
}

@media screen and (max-width: 767px) {
    
    .header h1 {
        font-size: 4vw;
    }

    .header h2 {
        font-size: 6vw;
        width: 70vw;
    }
    
    .header p {
        font-size: 3vw;
        width: 80vw;
    }

    .skill {
        font-size: 3vw;
    }
    
    .stack {
        font-size: 3vw;
    }

}


.medium-space {
    height: 100px;
}

.large-space {
    height: 300px;
}

.showcase-80v {
    /* margin-left: 10%; */
    /* margin-right:10%; */
    /* width: 80%; */
    width: auto;
    height: 80vh;
    margin-bottom: 20px;
}

.showcase-80h {
    width: 80vw;
    height: auto;
    margin-bottom: 20px;
}

.showcase-50h {
    width: 50vw;
    height: auto;
    margin-bottom: 20px;
}

.showcase-height {
    width: auto;
    height: 80vh;
    margin-bottom: 20px;
}

.showcase-4 {
    display: flex;
    justify-content: center;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 20px;
}

.showcase-4 img {
    width: 23%;
    margin-left: 20px;
    margin-right: 20px;
}

.showcase-2 {
    display: flex;
    justify-content: center;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 20px;
}

.showcase-2 img {
    width: 48%;
    margin-left: 10px;
    margin-right: 10px;
}

.showcase-double {
    display: flex;
    justify-content: center;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 20px;
}

.showcase-double img {
    width: 50%;
}

.showcase-single {
    /* display: flex; */
    /* justify-content: center; */
    margin-left: 10%;
    margin-right: 10%;
    width: 80%;
    height: auto;
    margin-bottom: 20px;
}