/*********** BASE ***********/

body, h1, h2, h3, p, ul, li {
  margin: 0;
  text-align: center;
}
  
p {
  margin-bottom: 10px;
  font-size: 1vw;
}

a {
  text-decoration: none; /* Remove the underline */
  color: inherit; /* Preserve the link color */
}

.home {
  color: white;
}

.social-links img {
  width: 50px;
  height: auto;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
}

/*********** INTRODUCTION ***********/

.intro-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: palevioletred;
  padding: 100px 70px;
}

.introduction {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.intro-left {
  width: 20vw;
  margin-right: 1vw;
}

.intro-left p {
  font-size: 4.5vw;
  font-weight: 900;
  line-height: 90%;
  text-align: right;
}

.intro-right {
  width: 20vw;
  font-size: 1.5vw;
  text-align: left;
  margin-left: 1vw;
}

.intro-right p {
  text-align: left;
  font-size: medium;
}


/*********** GALLERY ***********/

.gallery-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #111111;
  /* background-color: palevioletred; */
  padding: 50px 0;
  padding-top: 12vh;
}

.group {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 12vh;
}

.column {
  display: flex;
  flex-direction: column; 
}

.row {
  display: flex;
}

.group-name {
  /* color: #AAAAAA; */
  color: pink;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 3vw;
  text-align: center;
  margin-bottom: 5vh;
}


/*********** TILE ***********/

.tile {
  flex: 1;
  width: 17vw;
  margin: 5px;
  display: flex; /* Make tile a flex container */
  flex-direction: column; /* Stack tile-top and tile-bottom vertically */
  hyphens: auto;
}

.tile-top {
  height: 20vh; /* Fixed height for tile-top */
  background-color: white;
  border: 2px solid white;
  border-bottom: 0px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.tile-bottom {
  flex: 1;
  display: flex; /* Make tile-bottom a flex container */
  flex-direction: column; /* Stack tile-heading and any other content vertically */
  justify-content: top; /* Center content vertically */
  border: 2px solid white;
  background-color: rgba(255, 255, 255, 0.1);
  transition: background-color 0.1s;
}

.tile-heading {
  margin: 5px 10px;
  text-align: left;
  color: white;
  line-height: 1.1;
  font-size: small;
  transition: color 0.1s;
}

.tile:hover .tile-bottom {
  background-color: #EEEEEE; /* Change to the desired background color on hover */
}

.tile:hover .tile-heading {
  color: black;
  font-weight: bolder;
}


.tile-monsec {
  background-image: url('../public/images/cover_monsec.svg');
}

.tile-asnz {
  background-image: url('../public/images/cover_asnz.png');
}

.tile-wagp {
  background-image: url('../public/images/cover_wagp.svg');
}

.tile-wsc {
  background-image: url('../public/images/cover_wsc.svg');
}

.tile-dsow {
  background-image: url('../public/images/cover_dsow.svg');
}

.tile-bp {
  background-image: url('../public/images/cover_bp.png');
}

.tile-transapp {
  background-image: url('../public/images/cover_transapp.svg');
}

.tile-whatsnext {
  background-image: url('../public/images/cover_whatsnext.svg');
}

.tile-musiversal {
  background-image: url('../public/images/cover_musiversal.png');
}

.tile-mario {
  background-image: url('../public/images/cover_mario.png');
}

.tile-pokemon {
  background-image: url('../public/images/cover_pokemon.png');
}

.tile-locateall {
  background-image: url('../public/images/cover_locateall.png');
}


/* PHONE */

@media screen and (max-width: 1500px) {

  .introduction {
    flex-direction: column;
  }

  .intro-left {
    width: 60%;
    margin: 0;
    margin-bottom: 20px;
  }

  .intro-left p {
    font-size: 8.5vw;
    text-align: center;
  }

  .intro-right {
    width: 80%;
    margin: 0;
    margin-bottom: 20px;
  }

  .intro-right p {
    font-size: 3vw;
    text-align: center;
  }

  .tile {
    width: 25vw;
  }

  .tile-top {
    height: 15vh;
  }

  .group-name {
    font-size: 5vw;
  }

}